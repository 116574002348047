.item-footer-color {
    color: white
}

.position-logo {
    float: right;
}

.div-sinfony {
    display: flex;
    background-color: #474747;
    width: 100%;
    height: 40px;
    align-items: center;
    padding-left: 15px;
    color: white;
}

.copyright {
    padding-left: 0px;    

}
